import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { loadReCaptcha } from 'react-recaptcha-v3';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

// Components
import LoadingScreen from 'components/LoadingScreen';
import Header from 'components/Header';
import Footer from 'components/Footer/Footer';
import Intl from 'components/Intl';
import Button from 'components/Button';
import BookAppointment from 'src/components/BookAppointment';

// Assets
import Map from 'src/assets/images/footer/map.png';

// Styles
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import Popup from './components/Popup';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    loadReCaptcha('6Lfp2agaAAAAAJIaZS0qKsdr4EHpxEj5I4HW0Mp5');
  }

  generateRoutes() {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <Intl>
            {(t, { getLocaleURL }) => (
              <Root>
                <LoadingScreen />
                <Header />
                <Popup />
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="fade"
                  >
                    <Switch location={location}>{this.generateRoutes()}</Switch>
                  </CSSTransition>
                </TransitionGroup>
                <Footer
                  bgColor="#47889E"
                  color={theme.color.grey_dark}
                  logo={require('src/assets/images/logos/precidia-logo-white.svg')}
                  project="Precidia"
                  column1={
                    <>
                      <h3>{t.footer.column1.title}</h3>
                      <a className="footer-link" href="tel:604.422.0080">
                        604.422.0080
                      </a>
                      <a
                        className="footer-link"
                        href="info@precidiabyledmac.com"
                      >
                        info@precidiabyledmac.com
                      </a>
                      <Button
                        to={`${getLocaleURL()}/register`}
                        text={t.footer.column1.buttonLabel}
                      />
                    </>
                  }
                  column2={
                    <>
                      <h3>{t.footer.column2.title}</h3>
                      <a
                        href="https://www.google.com/maps/place/699+Whiting+Way+%232701,+Coquitlam,+BC+V3J+0N7/@49.2585982,-122.8941859,17z/data=!3m1!4b1!4m5!3m4!1s0x54867849c3b8a4c9:0x1f4a1228ef37a242!8m2!3d49.2585982!4d-122.891611?entry=ttu"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{t.footer.column2.text}</p>
                      </a>
                    </>
                  }
                  column3={
                    <>
                      <h3>{t.footer.column3.title}</h3>
                      <a
                        href="https://www.google.com/maps/place/699+Whiting+Way+%232701,+Coquitlam,+BC+V3J+0N7/@49.2585982,-122.8941859,17z/data=!3m1!4b1!4m5!3m4!1s0x54867849c3b8a4c9:0x1f4a1228ef37a242!8m2!3d49.2585982!4d-122.891611?entry=ttu"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FooterMap src={Map} />
                      </a>
                    </>
                  }
                  disclaimer={t.footer.disclaimer}
                />
                <BookAppointment />
              </Root>
            )}
          </Intl>
        )}
      />
    );
  }
}

const Root = styled.div`
  position: relative;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

const FooterMap = styled.img`
  ${vw('width', 158, 200)};
  height: auto;
`;

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
